import { useLandingContext } from 'holded/lib/context/landingContext';
import { TrustpilotLineV3Section, TrustpilotTheme } from 'holded/modules/cms/domain/components/testimonial';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

type ThemeOptions = {
  ratingLabelTextClass: string;
  ratingScoreTextClass: string;
  onTextClass: string;
  reviewsTextClass: string;
};

const THEME: Record<TrustpilotTheme, ThemeOptions> = {
  light: {
    ratingLabelTextClass: 'text-grey-900',
    ratingScoreTextClass: 'text-grey-500',
    onTextClass: 'text-grey-500',
    reviewsTextClass: 'text-grey-900',
  },
  soft: {
    ratingLabelTextClass: 'text-grey-900',
    ratingScoreTextClass: 'text-grey-500',
    onTextClass: 'text-grey-500',
    reviewsTextClass: 'text-grey-900',
  },
  dark: {
    ratingLabelTextClass: 'text-white',
    ratingScoreTextClass: 'text-white',
    onTextClass: 'text-white',
    reviewsTextClass: 'text-white',
  },
};

const THEME_DEFAULT = 'light';

const getTheme = (theme: TrustpilotTheme | undefined): ThemeOptions =>
  THEME[theme || THEME_DEFAULT] || THEME[THEME_DEFAULT];

const TrustpilotLineV3 = ({
  onText,
  reviewsText,
  ratingLabel,
  scoreImage,
  trustpilotUrl,
  ratingScoreText,
  theme,
  className,
}: TrustpilotLineV3Section) => {
  const {
    globalData: { global },
  } = useLandingContext();

  const ratingScoreValue = global?.attributes?.rating.value || '0';
  const totalReviewsCount = global?.attributes?.rating.count || '0';

  const { ratingLabelTextClass, ratingScoreTextClass, reviewsTextClass, onTextClass } = getTheme(theme);

  return (
    <a href={trustpilotUrl} target="_blank" rel="noreferrer" className="flex">
      <div className={`flex flex-wrap items-center justify-center sm:divide-x divide-gray-400  mx-auto ${className}`}>
        <div className="flex gap-2 px-4 py-1">
          <p className={`text-body3 font-medium whitespace-nowrap ${ratingLabelTextClass}`}>{ratingLabel}</p>
          <NextImage media={scoreImage.data} classImg="!h-5 w-auto" />
        </div>
        <p className="text-body3 text-center px-4 py-1">
          <span className={`mr-1 font-medium text-gray-900 ${ratingScoreTextClass}`}>
            {ratingScoreValue} {ratingScoreText}
          </span>
          <span className={`mr-1 ${onTextClass}`}>{onText}</span>
          <span className={`font-medium ${reviewsTextClass}`}>
            {totalReviewsCount} {reviewsText}
          </span>
        </p>
      </div>
    </a>
  );
};

export default TrustpilotLineV3;
